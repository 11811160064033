import React, { Component } from 'react';

export default class OutreachCoordinator extends Component {
  componentWillReceiveProps(nextProps) {
    if (!nextProps.info.newImage) {
      this.refs.file.value = '';
    }
  }

  updateField = (event) => {
    const { info, valuesChanged } = this.props;
    info[event.target.id] = event.target.value;
    valuesChanged(info);
  }

  fileSelected = (event) => {
    const { info, valuesChanged } = this.props;
    info.newImage = event.target.files[0];
    valuesChanged(info);
  }

  render() {
    const { info } = this.props;
    return (
      <div className="outreach-coordinator">
        <div className="form-group">
          <label htmlFor="email">Name</label>
          <input
            type="text"
            className="form-control"
            id="name"
            placeholder="John Doe"
            value={info.name}
            onChange={this.updateField}
          />

          <label htmlFor="email">Phone Number</label>
          <input
            type="number"
            className="form-control"
            id="mobile"
            placeholder="1234567890"
            value={info.mobile}
            onChange={this.updateField}
          />

          <label htmlFor="email">Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            placeholder="john.doe@example.com"
            value={info.email}
            onChange={this.updateField}
          />

          <label htmlFor="email">Image or YouTube Link</label>
          <input
            type="text"
            className="form-control"
            id="link"
            placeholder="https://www.henryford.com/"
            value={info.link}
            onChange={this.updateField}
          />

          <label className="image-label" htmlFor="image">
            <p>Upload a new image</p>
          </label>
          <input
            type="file"
            id="image"
            ref="file"
            onChange={this.fileSelected}
          />
        </div>

        <div>{info.errorMessage}</div>
      </div>
    );
  }
}
