import Helmet from 'react-helmet';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux'
import { renderRoutes } from 'react-router-config'
import { Redirect } from 'react-router-dom';

import Header from '../components/header';
import Sidebar from '../components/sidebar';

import { getIsSignedIn, signOut } from '../redux/actions/session-actions';

class DefaultLayout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isSignedIn: null
    }
  }

  componentDidMount() {
    this.props.getIsSignedIn()
      .then(isSignedIn => this.setState({ isSignedIn }))
  }

  render() {
    const { children, route, signOut } = this.props

    return this.state.isSignedIn === false
      ? (
        <Redirect to={{ pathname: "/signin" }} />
      )
      : (
        <div>
          <Helmet titleTemplate="Transplant - %s" />
          <Header signOut={signOut} />

          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-3 col-md-3 sidebar">
                <Sidebar />
              </div>

              <div className="col-sm-9 col-sm-offset-3 col-md-9 col-md-offset-3 main">
                {renderRoutes(route.routes)}
              </div>
            </div>
          </div>
        </div>
      );
  }
}

export default connect(null, { getIsSignedIn, signOut })(DefaultLayout)
