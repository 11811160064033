// const API_URL = 'http://localhost/api'
const API_URL = 'https://transplant.condor.io/api'

let store = null
let authToken = null

const _rawRequest = async ({ path, ...options }) => {
  const url = `${API_URL}${path}`

  options = options || {}
  options.credentials = 'include'

  const response = await fetch(url, options)

  const contentType = response.headers.get('Content-Type')
  const body = contentType && contentType.includes('application/json')
    ? await response.json()
    : await response.text()

  // const body = await response.json()
  if ((response.status / 100 | 0) === 2) {
    return body
  }

  // TODO: Throw a custom error.
  const error = new Error(body)
  error.body = body
  error.status = response.status

  // TODO: Rename to API_REQUEST_FAILED
  store && store.dispatch({
    type: 'FETCH_FAILED',
    payload: error
  })

  throw error
}

const _request = (options) => {
  const headers = options.headers || {}
  if (options.body) {
    headers['Content-Type'] = 'application/json'
  }

  return _rawRequest({
    ...options,
    headers,
    body: options.body ? JSON.stringify(options.body) : undefined
  })
}

export const listenToStore = (_store) => {
  store = _store

  store.subscribe(() => {
    const state = store.getState()
    authToken = state.user ? state.user.token : null
  })
}

export const get = async (path) => _request({
  method: 'GET',
  path
})
export const del = async (path) => _request({
  method: 'DELETE',
  path
})

export const post = async (path, body, additionalOptions) => _request({
  ...(additionalOptions || {}),
  method: 'POST',
  path,
  body
})

export const put = async (path, body, additionalOptions) => _request({
  ...(additionalOptions || {}),
  method: 'PUT',
  path,
  body
})

export const putRaw = async (path, body, additionalOptions) => _rawRequest({
  ...(additionalOptions || {}),
  method: 'PUT',
  path,
  body
})
export const postRaw = async (path, body, additionalOptions) => _rawRequest({
  ...(additionalOptions || {}),
  method: 'PUT',
  path,
  body
})
