import Helmet from 'react-helmet';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { asyncConnect } from 'redux-connect';
import { Link } from 'react-router-dom';
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove
} from 'react-sortable-hoc';

import {
  createPage,
  fetchAllPages,
  deletePage,
  reorderPages,
  updatePage
} from '../redux/actions/page-actions';

import PageEditor from './page-editor';

import '../scss/section-management-page.scss';

const pageSortFn = (a, b) =>
  a.order < b.order ? -1 : a.order > b.order ? 1 : 0;

const GrabHandle = SortableHandle(() => (
  <i className="fa fa-bars"></i>
));

const SortableItem = SortableElement(({ currentPath, page, section }) => {
  const classes = classNames({ active: currentPath.indexOf(page.id) > -1 })
  const name = page.is_editable
    ? <Link to={`${section}/${page.id}`}>{page.name}</Link>
    : page.name;
  return (
    <tr key={page.id}>
      <td className={classes}>
        <GrabHandle />
        {name}
      </td>
    </tr>
  );
});

const SortableList = SortableContainer(({ currentPath, pages, section }) => {
  const rows = pages
  .filter(p => p.section === section)
  .sort(pageSortFn)
    .map((p, index) => (
      <SortableItem
        index={index}
        key={p.id}
        currentPath={currentPath}
        page={p}
        section={section} />
    ));

  return (
    <table className="table table-striped">
      <thead>
        <tr>
           <th>Pages</th>
         </tr>
       </thead>
         <tbody>
           {rows}
         </tbody>
     </table>
  );
});

const asyncItems = [
  { promise: ({ store: {dispatch} }) => dispatch(fetchAllPages()) }
];

const mapStateToProps = (state, props) => ({
  pages: Object.values(state.entities.pages)
});

const mapDispatchToProps = {
  createPage,
  deletePage,
  reorderPages,
  updatePage
}

class SectionManagementPage extends PureComponent {
  savePage = (name, nav_name, rawContent) => {
    const { createPage, pages, updatePage } = this.props;
    const pageId = this.props.match.params.id;
    const page = pages.find(p => p.id === pageId);

    const section = this.getSection();
    const numSectionPages = pages.filter(p => p.section === section).length;

    return (pageId === 'add')
      ? createPage(name, nav_name, this.getSection(), rawContent, numSectionPages)
          .then(id => {
            this.props.router.replace(`${this.getSection()}/${id}`);
          })
      : updatePage(pageId, name, nav_name, rawContent, page.order)
  }

  deletePage = () => {
    const pageId = this.props.match.params.id;

    this.props.deletePage(pageId);
    this.props.router.replace(this.getSection());
  }

  onSortPages = ({ oldIndex, newIndex }) => {
    const { pages, reorderPages } = this.props;
    const section = this.getSection();

    const sortedPages = pages
      .filter(p => p.section === section)
      .sort(pageSortFn);

    const newSortedPages = arrayMove(sortedPages, oldIndex, newIndex)
      .map((p, index) => ({
        id: p.id,
        order: index
      }));

    reorderPages(newSortedPages)
  }

  getSection() {
    const pageId = this.props.match.params.id;
    let section = this.props.location.pathname;
    return !pageId ? section : section.substr(0, section.lastIndexOf('/'));
  }

  render() {
    console.log('rendering smp')
    const { location, pages } = this.props;

    const currentPath = location.pathname;

    const pageId = this.props.match.params.id;

    const page = pageId && pageId !== 'add' && pages.find(p => p.id === pageId);

    const section = this.getSection();

    const pageEditor = pageId && (
      <PageEditor
        page={page}
        pages={pages}
        savePage={this.savePage}
        deletePage={this.deletePage}
      />
    );

    return (
      <div className="section-management-page">
        <Helmet title="Manage Sections" />

        <h3>Manage Sections</h3>

        <div className="row">
          <div className="col-md-4">
            <div className="table-responsive">
              <SortableList
                helperClass="sortableHelper"
                currentPath={currentPath}
                pages={pages}
                section={section}
                onSortEnd={this.onSortPages}
                useDragHandle={true}
              />

               <Link to={`${section}/add`}>
                 <button className="btn btn-primary">+ Add Page</button>
               </Link>
             </div>
          </div>

          <div className="col-md-8">
            {pageEditor}
          </div>
        </div>
      </div>
    );
  }
}

export default asyncConnect(asyncItems, mapStateToProps, mapDispatchToProps)(SectionManagementPage)
