import React from 'react';
import { hot } from 'react-hot-loader'
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { renderRoutes } from 'react-router-config'
import { connect, Provider } from 'react-redux'
import { ReduxAsyncConnect } from 'redux-connect'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'font-awesome/css/font-awesome.min.css'
import './scss/main.scss'

import store from './redux/store'
import { listenToStore } from './api'
import { loadUser } from './redux/actions/auth'

import DefaultLayout from './layouts/default'

import SignInPage from './pages/sign-in-page';
import SignUpPage from './pages/sign-up-page';

import AdminsPage from './pages/admins-page';
import DoctorManagementPage from './pages/doctor-management-page';
import EditDoctorPage from './pages/edit-doctor-page';
import Home from './pages/home-page';
import OutreachCoordinatorsPage from './pages/outreach-coordinators-page';
import Page404 from './pages/page-404';
import PageEditor from './pages/page-editor';
import SectionManagementPage from './pages/section-management-page';

// API library listens to the store to listen for token changes.
listenToStore(store)

// Load user from localStorage if possible.
loadUser()(store.dispatch)

const App = ({ route }) => {
  return renderRoutes(route.routes)
}

const routes = [{
  path: '/',
  component: App,
  routes: [
    {
      path: '/signin',
      component: SignInPage,
    },
    {
      path: '/signup',
      component: SignUpPage,
    },

    {
      component: DefaultLayout,
      routes: [
        {
          path: '/doctors',
          exact: true,
          component: DoctorManagementPage,
        },
        {
          path: '/doctors/:id',
          component: EditDoctorPage,
        },
        {
          path: '/outreach',
          exact: true,
          component: OutreachCoordinatorsPage,
        },
        {
          path: '/organs/:organ/:id?',
          component: SectionManagementPage
        },
        {
          path: '/journals/:id?',
          component: SectionManagementPage
        },
        {
          path: '/locations/:id?',
          component: SectionManagementPage
        },
        {
          path: '/speakers/:id?',
          component: SectionManagementPage
        },
        {
          path: '/lectures/:id?',
          component: SectionManagementPage
        },
        {
          path: '/edit/:id',
          component: PageEditor
        },
        {
          path: '/admins',
          component: AdminsPage
        },
      ]
    }
  ]
}]

export default hot(module)(
  () => (
    <Provider store={store}>
      <Router>
        <ReduxAsyncConnect routes={routes} />
      </Router>
    </Provider>
  )
)
