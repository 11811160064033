import { del, get, post, put } from '../../api'
import { InvitationEntity, UserEntity } from './entities';
import { normalize } from 'normalizr';

export function deleteInvitation(id) {
  return dispatch =>
    del(`/invitations/${id}`)
      .then(() => {
        dispatch({
          type: 'DELETE_INVITATION_SUCCEEDED',
          payload: { id }
        });
      })
      .catch(e => dispatch({ type: 'DELETE_INVITATION_FAILED', e }));
}

export function deleteUser(id) {
  return dispatch =>
    del(`/users/${id}`)
      .then(() => {
        dispatch({
          type: 'DELETE_USER_SUCCEEDED',
          payload: { id }
        });
      })
      .catch(e => dispatch({ type: 'DELETE_USER_FAILED', e }));
}

export function fetchMe() {
  return dispatch =>
    get('/users/me')
      .then(body => {
        const me = body.data;
        dispatch({
          type: 'FETCH_ME_SUCCEEDED',
          payload: { ...me }
        });
      })
      .catch(error => dispatch({ type: 'FETCH_ME_FAILED', error }));
}

export function fetchUsers() {
  return dispatch => {
    return get('/users')
      .then(body => {
        dispatch({
          type: 'FETCH_USERS_SUCCEEDED',
          payload: { entities: normalize(body.data, [ UserEntity ]).entities }
        });
      })
      .catch(error => dispatch({ type: 'FETCH_USERS_FAILED', error }));
  };
}

export function fetchUserInvitations() {
  return dispatch =>
    get('/invitations')
      .then(body => {
        dispatch({
          type: 'FETCH_INVITATIONS_SUCCEEDED',
          payload: { entities: normalize(body.data, [ InvitationEntity ]).entities }
        });
      })
      .catch(error => dispatch({ type: 'FETCH_INVITATIONS_FAILED', error }));
}

export function inviteUser(email) {
  return dispatch =>
    post('/invitations', { email })
      .then(body => {
        dispatch({
          type: 'INVITE_USER_SUCCEEDED',
          payload: { entities: normalize(body.data, InvitationEntity).entities }
        });
      })
      .catch(e => dispatch({ type: 'INVITE_USER_FAILED', e }));
}
