import {
  del,
  get,
  post,
  put,
  putRaw
} from '../../api'
import { DoctorEntity } from './entities';
import { normalize } from 'normalizr';

export const addDoctor = (payload) => dispatch =>
  post(`/doctors`, payload)
    .then(body => {
      const entities = normalize(body, { data: DoctorEntity }).entities;
      dispatch({
        type: 'CREATE_DOCTOR_SUCCEEDED',
        payload: { entities }
      });
      return body.data.id;
    })
    .catch(error => dispatch({
      type: 'CREATE_DOCTOR_FAILED',
      error
    }));

export const deleteDoctor = (id) => dispatch =>
  del(`/doctors/${id}`)
    .then(() => dispatch({
      type: 'DELETE_DOCTOR_SUCCEEDED',
      payload: { id }
    }))
    .catch(e => dispatch({
      type: 'DELETE_DOCTOR_FAILED',
      e
    }));

export const fetchAllDoctors = () => dispatch =>
  get('/doctors')
    .then(body => {
      const entities = normalize(body, { data: [DoctorEntity] }).entities;
      return dispatch({
        type: 'FETCH_ALL_DOCTORS_SUCCEEDED',
        payload: { entities }
      });
    })
    .catch(error => dispatch({
      type: 'FETCH_ALL_DOCTORS_FAILED',
      error
    }));

export const setDoctorOrgans = (id, organs) => dispatch =>
  put(`/doctors/${id}/organs`, { organs })
    .then(body => {
      return dispatch({
        type: 'SET_DOCTOR_ORGANS_SUCCEEDED',
        payload: { id, organs: organs.map(o => o.id) }
      });
    })
    .catch(error => dispatch({
      type: 'SET_DOCTOR_ORGANS_FAILED',
      error
    }));

export const updateDoctor = (id, file, info) => dispatch => {
  // TODO: TEST THIS!
  const data = new FormData();
  data.append('info', JSON.stringify(info));

  if (file) {
    data.append('file', file);
  }

  return putRaw(`/doctors/${id}`, data)
    .then(body => {
      const entities = normalize(body, { data: DoctorEntity }).entities;
      dispatch({
        type: 'UPDATE_DOCTOR_SUCCEEDED',
        payload: { entities }
      });
    })
    .catch(error => dispatch({ type: 'UPDATE_DOCTOR_FAILED', error }));
};
