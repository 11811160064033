import React, { Component, PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { uploadFile } from '../files';
import { postRaw } from '../api'

import tinymce from 'tinymce';
import 'tinymce/themes/modern';
import 'tinymce/plugins/code';
import 'tinymce/plugins/image';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';

import '../scss/page-editor.scss';

const TEAM_PAGES = [
  {
    title: 'Liver Team',
    value: 'team/liver'
  },
  {
    title: 'Bowel Team',
    value: 'team/bowel'
  },
  {
    title: 'Kidney Team',
    value: 'team/kidney'
  },
  {
    title: 'Lung Team',
    value: 'team/lung'
  },
  {
    title: 'Heart Team',
    value: 'team/heart'
  },
  {
    title: 'Bone Marrow Team',
    value: 'team/marrow'
  },
  {
    title: 'Pancreas Team',
    value: 'team/pancreas'
  },
  {
    title: 'HPB Team',
    value: 'team/hpb'
  },
  {
    title: 'Make A Referral',
    value: 'MakeAReferral'
  }
].map(p => Object.assign({}, p, { value: `#${p.value}` }));

const linkSortFn = (a, b) => {
  a = a.title;
  b = b.title;
  return (a < b) ? -1 : (a > b) ? 1 : 0;
}

class Tiny extends Component {
  constructor() {
    super();
    this.state = { editor: null };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.pageId !== nextProps.pageId) {
      this.state.editor && this.state.editor.setContent(nextProps.content);
    }
  }

  componentDidMount() {
    const suggestedLinks = this.props.pages.map(p => ({
      title: p.name, value: `#${p.id}`
    }))
      .filter(p => p.title.toLowerCase() !== 'make a referral')
      .concat(TEAM_PAGES)
      .sort(linkSortFn);

    tinymce.init({
      selector: `#${this.props.id}`,
      skin_url: process.env.PUBLIC_URL + '/skins/lightgray',
      themes: 'modern',
      menubar: false,
      inline_styles : true,
      plugins: 'code image link media lists',
      toolbar: 'undo redo | image | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | fontsizeselect | media | link | code',
      setup: editor => {
        this.setState({ editor });
        editor.on('keyup change', () => {
          const content = editor.getContent();
          this.props.onEditorChange(content);
        });
      },
      link_list: suggestedLinks,
      file_picker_types: ['image'],
      file_picker_callback: (cb, value, meta) => {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', 'image/*');

        input.onchange = function() {
          const file = this.files[0];

          const reader = new FileReader();
          reader.onload = function () {
            // Note: Now we need to register the blob in TinyMCEs image blob
            // registry. In the next release this part hopefully won't be
            // necessary, as we are looking to handle it internally.
            var id = 'blobid' + (new Date()).getTime();
            var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
            var base64 = reader.result.split(',')[1];
            var blobInfo = blobCache.create(id, file, base64);
            blobCache.add(blobInfo);

            // call the callback and populate the Title field with the file name
            cb(blobInfo.blobUri(), { title: file.name });
          };
          reader.readAsDataURL(file);
        };

        input.click();
      },
      images_upload_handler: function (blobInfo, success, failure) {
        uploadFile(blobInfo.blob())
          .then(link => success(link))
          .catch(error => failure('Unable to upload image.'))
      }
    });
  }

  componentWillUnmount() {
    tinymce.remove(this.state.editor);
  }

  render() {
    return (
      <textarea
        id={this.props.id}
        value={this.props.content}
        onChange={e => console.log(e)}
      />
    );
  }
}

export default class PageEditor extends PureComponent {
  constructor(props) {
    super(props);
    this.state = this.getEditorState(props);
  }

  componentWillReceiveProps(newProps) {
    this.setState(this.getEditorState(newProps))
  }

  getEditorState(props) {
    const { page } = props;
    if (!page) {
      return { pageName: '', navName: '', content: '', isSaved: false };
    }

    return { pageName: page.name, navName: page.nav_name || '', content: page.content, isSaved: false };
  }

  pageNameChanged = (event) => {
    this.setState({ pageName: event.target.value })
  }

  navNameChanged = (event) => {
    this.setState({ navName: event.target.value })
  }

  onEditorChange = (content) => {
     this.setState({ content });
  }

  savePage = () => {
    const name = this.refs.name.value;
    const nav_name = this.refs.nav_name.value;
    this.props.savePage(name, nav_name, this.state.content)
      .then(() => {
        this.setState({ isSaved: true })
        setTimeout(() => this.setState({ isSaved: false }), 2000);
      });
  }

  uploadImageCallback = (file) => {
    const data = new FormData();
    data.append('file', file);

    return postRaw(`/assets`, data)
      .then(body => {
        return { data: { link: body.data.url } };
      });
  }

  render() {
    const { page, pages } = this.props;
    if (!page) {
      return (
        <div>Loading...</div>
      )
    }

    const deleteButton = page
      && <button className="btn btn-danger" onClick={this.props.deletePage}>Delete</button>;

    const saveButton = this.state.isSaved
      ? <button className="btn btn-primary disabled">Saved!</button>
      : <button className="btn btn-primary" onClick={this.savePage}>Save</button>;
    return (
      <div className="page-editor">
        <div className="page-editor-actions row">
          <div className="col-xs-6">
            {deleteButton}
          </div>
          <div className="col-xs-6 text-right">
            {saveButton}
          </div>
        </div>
        <input
          ref="name"
          type="text"
          className="form-control input-lg"
          placeholder="Page Name"
          value={this.state.pageName}
          onChange={this.pageNameChanged}
        />
        <input
          ref="nav_name"
          type="text"
          className="form-control input-lg"
          placeholder="Sidebar Menu Name"
          value={this.state.navName}
          onChange={this.navNameChanged}
        />

        {page.name === "Videos" && (
          <div>
            <h2>How to add a video:</h2>
            <ul>
              <li className='page-editor-list-text'>Click source code button "&lt; &gt;"</li>
              <li className='page-editor-list-text'>Paste in this code, replacing highlighted areas with the video's title and ID:</li>
              <li className='page-editor-list-text'>Save</li>
              <li className='page-editor-list-text'>Note: Make sure youtube src link saves with https:</li>
              <pre className='page-editor-code-container'>
                <code className='page-editor-code'>
                  <code>&lt;div style="background-color: #d2e5f5; margin-bottom: 20px; padding: 18px; padding-top: 5px;"&gt;</code>
                    <code style={{marginLeft:18}}>&lt;h1 style="font-family: Helvetica Neue; font-size: 17.6px; font-weight: bold; color: #003e74; padding-right: 18px;"&gt;<code style={{backgroundColor:'yellow'}}>Ela Bullock Health System</code>&lt;/h1&gt;</code>
                    <code style={{marginLeft:18}}>&lt;iframe style="height: 192px; background-color: #000;" title="YouTube video player" src="https://www.youtube.com/embed/<code style={{backgroundColor:'yellow'}}>jmiInW7mE4c</code>" width="341" height="192" frameborder="0" allowfullscreen="allowfullscreen"&gt;&lt;/iframe&gt;</code>
                  <code>&lt;/div&gt;</code>
                </code>
              </pre>
            </ul>
          </div>
        )}

        <div className="editor-container fg-line">
          <Tiny
            id="tiny-editor"
            pages={pages}
            pageId={page.id}
            content={this.state.content}
            onEditorChange={this.onEditorChange}
          />      
        </div>
      </div>
    );
  }
}