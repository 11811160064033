import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { asyncConnect } from 'redux-connect';

import { signUp } from '../redux/actions/session-actions';

import '../scss/sign-in-page.scss';

const mapStateToProps = (state, props) => ({
  isSignedIn: state.getIn(['session', 'isSignedIn'])
});

const mapDispatchToProps = (dispatch) => ({
  signUp: (...args) => dispatch(signUp(...args))
});

class SignUpPage extends PureComponent {
  static contextTypes = {
    store: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { isSigningUp: false };
  }

  signUp = (event) => {
    event.preventDefault();

    this.setState({ isSigningUp: true });

    const code = this.props.params.code;
    const name = this.refs.name.value;
    const password = this.refs.password.value;

    this.props.signUp(code, { name, password })
      .then(() => this.props.router.push('/'))
      .catch(() => this.setState({ isSigningUp: false }));
  }

  render() {
    const signUpButton = this.state.isSigningUp
      ? (<button className="btn btn-lg btn-primary btn-block" type="submit" disabled><i className="fa fa-spinner fa-spin"></i> Activating...</button>)
      : (<button className="btn btn-lg btn-primary btn-block" type="submit">Activate</button>);
    return (
      <div>
        <Helmet title="Activate Your Account" />

        <nav className="navbar navbar-fixed-top">
          <div className="container-fluid">
            <div className="navbar-header">
              <a className="navbar-brand" href="#">
                <img src="images/hftr-logo.svg" height="50" />
              </a>
            </div>
          </div>
        </nav>

        <div className="container">

          <form className="form-signin" onSubmit={this.signUp}>
            <h3 className="form-signin-heading">Activate Your Account</h3>

            <label htmlFor="name" className="sr-only">Full Name</label>
            <input
              ref="name"
              type="text"
              id="name"
              className="form-control"
              placeholder="Full Name"
              required
              autoFocus
            />

            <label htmlFor="password" className="sr-only">Password</label>
            <input
              ref="password"
              type="password"
              id="password"
              className="form-control"
              placeholder="Password"
              required
            />

            <label htmlFor="confirmPassword" className="sr-only">Confirm Password</label>
            <input
              ref="confirmPassword"
              type="password"
              id="confirmPassword"
              className="form-control"
              placeholder="Confirm Password"
              required
            />

            {signUpButton}
          </form>
        </div>
      </div>
    );
  }
}

export default asyncConnect([], mapStateToProps, mapDispatchToProps)(SignUpPage)
