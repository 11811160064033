import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { asyncConnect } from 'redux-connect';
import useForm from 'react-hook-form'
import { useHistory } from 'react-router-dom'

import {
  addDoctor,
  deleteDoctor,
  fetchAllDoctors,
  updateDoctor
} from '../redux/actions/doctor-actions';

import '../scss/edit-doctor-page.scss';

const DEFAULT_IMAGE = 'https://s3.amazonaws.com/transplant.dbadetroit.com/default-doctor.jpg';

var emailRe = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const validateEmail = (str) => emailRe.test(str);

const asyncItems = [
  {
    promise: ({ store: {dispatch} }) =>
      dispatch(fetchAllDoctors())
  }
];

const mapStateToProps = (state, { match: { params: { id } } }) => ({
  doctor: state.entities.doctors[id]
});

const EditDoctorPage = ({
  addDoctor,
  deleteDoctor,
  doctor,
  updateDoctor,
  match,
}) => {
  const history = useHistory()

  const { id } = match.params;
  const [ errorMessages, setErrorMessages ] = useState(null)
  const [ image, setImage ] = useState(null)
  const {
    errors,
    handleSubmit,
    register,
    reset,
    setError
  } = useForm()

  const validateForm = (values) => {
    const { name, mobile, email, link } = values;

    const errorMessages = [];

    if (name.length < 1) {
      errorMessages.push('Invalid name.');
    }

    if (mobile.length < 10) {
      errorMessages.push('Invalid phone number. Number should be 10 digits, no spaces or punctuation.');
    }

    if (!validateEmail(email)) {
      errorMessages.push('Invalid email.');
    }

    if (link.length < 1) {
      errorMessages.push('Invalid link.');
    }

    if (errorMessages.length) {
      setErrorMessages({ __html: errorMessages.join('<br />') });
      return false;
    }

    setErrorMessages(null)
    return true;
  }

  const onSubmit = (values) => {
    if (!validateForm(values)) {
      return;
    }

    // TODO: This is stupid.
    const patch = values;

    if (id === 'add') {
      addDoctor(patch)
        .then(id => {
          if (image) {
            updateDoctor(id, image && image.file, patch);
          }
        })
    } else {
      updateDoctor(id, image && image.file, patch);
    }

    history.push('/doctors');
  }

  const fileSelected = (event) => {
    const newImage = event.target.files[0];
    const reader = new FileReader();
    const url = reader.readAsDataURL(newImage);
    reader.onloadend = (e) => setImage({ image: reader.result, file: newImage });
  }

  const delDoctor = () => {
    deleteDoctor(id);
    history.push('/doctors');
  }

  const initialValues = doctor || {}

  const title = id === 'add' ? 'New Doctor' : 'Edit Doctor';
  const buttonText = id === 'add' ? 'Add' : 'Save';

  const deleteButton = id !== 'add'
    ? (
      <button onClick={delDoctor} className="btn btn-danger">Delete</button>
    ) : null;

  return (
    <div className="edit-doctor-page">
      <Helmet title={title} />

      <h3>{title}</h3>

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-group">
          <label className="image-label" htmlFor="image">
            <img src={image ? image.image : initialValues.image} width="100" />
            <p>Click to replace</p>
          </label>
          <input
            type="file"
            id="image"
            className="hidden"
            onChange={fileSelected}
          />

          <label htmlFor="email">Name</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            defaultValue={initialValues.name || ''}
            ref={register}
            placeholder="John Doe"
          />

          <label htmlFor="email">Phone Number</label>
          <input
            type="number"
            className="form-control"
            id="mobile"
            name="mobile"
            defaultValue={initialValues.mobile || ''}
            ref={register}
            placeholder="1234567890"
          />

          <label htmlFor="email">Email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            name="email"
            defaultValue={initialValues.email || ''}
            ref={register}
            placeholder="john.doe@example.com"
          />

          <label htmlFor="email">Link</label>
          <input
            type="text"
            className="form-control"
            id="link"
            name="link"
            defaultValue={initialValues.link || ''}
            ref={register}
            placeholder="https://www.henryford.com/"
          />
        </div>

        <div className="text-danger" dangerouslySetInnerHTML={errorMessages} />

        <div className="row">
          <div className="col-xs-6">
            <button className="btn btn-primary" type="submit">{buttonText}</button>
          </div>
          <div className="col-xs-6 text-right">
            {deleteButton}
          </div>
        </div>
      </form>
    </div>
  );
}

export default asyncConnect(asyncItems, mapStateToProps, {
  addDoctor,
  deleteDoctor,
  updateDoctor
})(EditDoctorPage)
