import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import debounce from 'debounce';
import React, { PureComponent } from 'react';
import { asyncConnect } from 'redux-connect';

import { fetchAllDoctors, setDoctorOrgans } from '../redux/actions/doctor-actions';

import '../scss/doctor-management-page.scss';

// TODO: This is very fragile.
const suffixes = [
  'MD',
  'M.D.',
  'MS',
  'M.S.',
  'PA',
  'P.A.',
  'PhD',
  'Ph.D.',
  'MHSA',
  'M.H.S.A.',
  'DO',
  'D.O.',
  'NP',
  'N.P.',
  'C.N.P.'
].map(s => s.replace(/\./g, '\\.'));
const removeSuffixesRe = new RegExp(`,?\\s?((${suffixes.join(')|(')}))`, 'g');
const getLastName = (name) => {
  const suffixless = name.replace(removeSuffixesRe, '');
  const m = suffixless.match(/\w+$/);
  return m[0];
}

const doctorSort = (a, b) => {
  a = getLastName(a.name);
  b = getLastName(b.name);
  return (a < b) ? -1 : (a > b) ? 1 : 0;
}

const formatPhoneNumber = n =>
  `(${n.substr(0, 3)}) ${n.substr(3, 3)}-${n.substr(6)}`;

const debounceEventHandler = (...args) => {
  const debounced = debounce(...args, 500);
  return e => {
    e.persist()
    return debounced(e);
  }
}

const asyncItems = [
  {
    promise: ({ store: {dispatch} }) => {

      console.log('calling fetch')
      return dispatch(fetchAllDoctors())
    }
  }
];

const mapStateToProps = (state, props) => ({
  doctors: Object.values(state.entities.doctors),
  organs: Object.values(state.entities.organs)
});

const mapDispatchToProps = {
  setDoctorOrgans,
  fetchAllDoctors
}
//   (dispatch) => ({
//   setDoctorOrgans: (...args) => dispatch(setDoctorOrgans(...args))
// });

class DoctorManagementPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { nameFilter: '' }
  }

  componentDidMount() {
    this.props.fetchAllDoctors()
  }

  searchChanged = debounceEventHandler((event) => {
    this.setState({ nameFilter: event.target.value });
  })

  toggleDocOrgan = (doctorId, organId) => {
    let organs = this.props.doctors.find(d => d.id === doctorId).organs;
    const index = organs.indexOf(organId);
    if (index > -1) {
      organs.splice(index, 1);
    } else {
      organs.push(organId);
    }
    this.props.setDoctorOrgans(doctorId, organs.map(id => ({ id })));
  }

  render() {
    let { doctors, organs } = this.props;
    let { nameFilter } = this.state;

    if (nameFilter) {
      doctors = doctors.filter(doc =>
        doc.name.toLowerCase().indexOf(nameFilter.toLowerCase()) > -1);
    }

    doctors = doctors.sort(doctorSort);

    doctors = doctors.map(doc => {
      const organCheckboxes = organs.map(o => {
        return (
          <div className="checkbox" key={doc.id + o.id}>
            <label>
              <input
                type="checkbox"
                checked={doc.organs.indexOf(o.id) > -1}
                onChange={this.toggleDocOrgan.bind(null, doc.id, o.id)}
              /> {o.name}
            </label>
          </div>
        );
      });

      return (
        <div className="col-md-3 doctor-card" key={doc.id}>
          <Link to={`/doctors/${doc.id}`}>
            <img src={doc.image} width="100" />
          </Link>
          <div>{doc.name}</div>
          <div>{formatPhoneNumber(doc.mobile)}</div>
          <div>{doc.email}</div>
          <div><a href={doc.link} target="_blank">Link <i className="fa fa-external-link" aria-hidden="true"></i></a></div>
          <div>{organCheckboxes}</div>
        </div>
      );
    });

    return (
      <div className="doctor-management-page">
        <Helmet title="Doctors" />

        <h3>Manage Doctors</h3>

        <div className="text-right">
          <Link to="/doctors/add">
            <button className="btn btn-primary">+ New Doctor</button>
          </Link>
        </div>

        <div className="doctor-search form-group">
          <input
            type="text"
            placeholder="Search by name..."
            onChange={this.searchChanged}
          />
        </div>

        {doctors.length > 0 ? doctors : <div>No doctors found.</div>}
      </div>
    );
  }
}

export default asyncConnect(asyncItems, mapStateToProps, mapDispatchToProps)(DoctorManagementPage)
