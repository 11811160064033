import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { reducer as reduxAsyncConnect } from 'redux-connect'

import * as reducers from './reducers'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(combineReducers({
  ...reducers,
  reduxAsyncConnect
}), composeEnhancers(applyMiddleware(thunk)))

export default store
