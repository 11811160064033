import classNames from 'classnames';
import React, { PureComponent } from 'react';
import { Link, useHistory } from 'react-router-dom'; 

const MENU = [
  {
    name: 'Doctors',
    path: '/doctors'
  },
  {
    name: 'Outreach Coordinators',
    path: '/outreach'
  },
  {
    name: 'Organs',
    menu: [
      {
        name: 'Liver',
        path: '/organs/liver'
      },
      {
        name: 'Kidney',
        path: '/organs/kidney'
      },
      {
        name: 'Pancreas',
        path: '/organs/pancreas'
      },
      {
        name: 'Small Bowel & Multivisceral',
        path: '/organs/bowel'
      },
      {
        name: 'Lung',
        path: '/organs/lung'
      },
      {
        name: 'Heart',
        path: '/organs/heart'
      },
      {
        name: 'Bone Marrow Stem Cell Transplant',
        path: '/organs/marrow'
      },
      {
        name: 'Liver, Pancreas, & Biliary Tract Tumors',
        path: '/organs/hpb'
      },
      {
        name: 'Ventricular Assist Device',
        path: '/organs/vad'
      }
    ]
  },
  {
    name: 'Journals',
    path: '/journals'
  },
  {
    name: 'Locations',
    path: '/locations'
  },
  {
    name: 'Speakers',
    path: '/speakers'
  },
  {
    name: 'Lectures',
    path: '/lectures'
  },
  {
    name: 'Admins',
    path: '/admins'
  }
];

const renderSubMenu = (subMenu, currentPath) => {
  if (!subMenu || subMenu.length === 0) {
    return null;
  }

  const items = subMenu.map(m => {
    const classes = classNames({
      active: currentPath.indexOf(m.path) > -1
    });
    return (
      <li
        className={classes}
        key={m.path}>
        <Link to={m.path}>{m.name}</Link>
      </li>
    );
  });

  return (<ul>{items}</ul>);
}

const renderMenu = (menu, currentPath) =>
  menu.map(m => {
    const classes = classNames({
      'sub-menu': true,
      'active': currentPath.indexOf(m.path) > -1
    });

    const link = !!m.path
      ? <Link to={m.path}>{m.name}</Link>
      : <span>{m.name}</span>;

    return (
      <li
        className={classes}
        key={m.path || 'nolink'}>
        {link}

        {renderSubMenu(m.menu, currentPath)}
      </li>
    );
  });


const Sidebar = () => {
  const history = useHistory()

  // TODO: Link up
  const currentUser = { role: 'Administrator' }
  const menu = currentUser.role !== 'Administrator'
    ? MENU.filter(m => m.path !== '/admins')
    : MENU;

  return (
    <ul className="nav nav-sidebar">
      {renderMenu(menu, history.location.pathname)}
    </ul>
  )
}

export default Sidebar
