import { del, get, post, put } from '../../api'
import { OutreachCoordinatorEntity } from './entities';
import { normalize } from 'normalizr';
import { uploadFile } from '../../files';

export function addOutreachCoordinator(payload) {
  return dispatch =>
    post(`/outreach`, payload)
      .then(body => {
        const entities = normalize(body, { data: OutreachCoordinatorEntity }).entities;
        dispatch({
          type: 'ADD_OUTREACH_COORDINATOR_SUCCEEDED',
          payload: { entities }
        });
      })
      .catch(error => dispatch({ type: 'CREATE_OUTREACH_COORDINATOR_FAILED', error }));
}

export function deleteOutreachCoordinator(id) {
  return dispatch =>
    del(`/outreach/${id}`)
      .then(() => {
        dispatch({
          type: 'DELETE_OUTREACH_COORDINATOR_SUCCEEDED',
          payload: { id }
        });
      })
      .catch(e => dispatch({ type: 'DELETE_OUTREACH_COORDINATOR_FAILED', e }));
}

export function fetchAllOutreachCoordinators() {
  return dispatch => {
    return get('/outreach')
      .then(body => {
        const entities = normalize(body, { data: [OutreachCoordinatorEntity] }).entities;
        dispatch({
          type: 'FETCH_ALL_OUTREACH_COORDINATORS_SUCCEEDED',
          payload: { entities }
        });
      })
      .catch(error => {
        dispatch({ type: 'FETCH_ALL_OUTREACH_COORDINATORS_FAILED', error });
      });
  };
}

// Uploads an image if set.
const maybeUploadImage = (info) => {
  if (!info.newImage) {
    return Promise.resolve(info);
  }

  return uploadFile(info.newImage)
    .then(link => {
      delete info.newImage;
      info.link = link;
      return info;
    })
};

export function updateOutreachCoordinator(id, info) {
  return dispatch => {
    return maybeUploadImage(info)
      .then(info => put(`/outreach/${id}`, info))
      .then(body => {
        const entities = normalize(body, { data: OutreachCoordinatorEntity }).entities;
        dispatch({
          type: 'UPDATE_OUTREACH_COORDINATOR_SUCCEEDED',
          payload: { entities }
        });
      })
      .catch(error => {
        dispatch({ type: 'UPDATE_OUTREACH_COORDINATOR_FAILED', error })
      });
  };
}
