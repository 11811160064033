import Helmet from 'react-helmet';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { asyncConnect } from 'redux-connect';
import { useHistory } from 'react-router-dom'
import useForm from 'react-hook-form'

import { signIn } from '../redux/actions/session-actions';

import '../scss/sign-in-page.scss';

const mapStateToProps = (state, props) => ({
  session: state.session
});

const mapDispatchToProps = (dispatch) => ({
  signIn: (...args) => dispatch(signIn(...args))
});

const SignInPage = ({
  session,
  signIn
}) => {
  const history = useHistory()
  const [isSigningIn, setIsSigningIn] = useState(false)
  const {
    errors,
    handleSubmit,
    register,
    reset,
    setError
  } = useForm()

  const onSubmit = (values) => {
    setIsSigningIn(true)

    signIn({
      email: values.email,
      password: values.password
    })
      .then(() => history.push('/doctors'))
      .catch(() => setIsSigningIn(false))
  }

  const errorMessage = session.error
    ? <p className="text-danger">Invalid username or password.</p>
    : null;

  const submitButton = isSigningIn
    ? (<button className="btn btn-lg btn-primary btn-block" type="submit" disabled><i className="fa fa-spinner fa-spin"></i> Signing in...</button>)
    : (<button className="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>);

  return (
    <div>
      <Helmet title="Transplant Sign In" />

      <nav className="navbar navbar-fixed-top">
        <div className="container-fluid">
          <div className="navbar-header">
            <a className="navbar-brand" href="#">
              <img src="images/hftr-logo.svg" height="50" />
            </a>
          </div>
        </div>
      </nav>

      <div className="container">
        <form className="form-signin" onSubmit={handleSubmit(onSubmit)}>
          <h3 className="form-signin-heading">Sign In</h3>

          {errorMessage}

          <label htmlFor="email" className="sr-only">Email address</label>
          <input
            type="text"
            id="email"
            name="email"
            ref={register}
            className="form-control"
            placeholder="Email address"
            required
            autoFocus
          />

          <label htmlFor="password" className="sr-only">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            ref={register}
            className="form-control"
            placeholder="Password"
            required
          />
          {submitButton}
        </form>
      </div>
    </div>
  );
}

export default asyncConnect([], mapStateToProps, mapDispatchToProps)(SignInPage)
