import { buildReducer } from '../utils'

const handlers = {}

// TODO: Pull from const
handlers.LOAD_USER = (state, action) => {
  if (!window) {
    return state
  }

  const rawUser = window.localStorage.getItem('user')
  if (!rawUser) {
    return state
  }

  try {
    const user = JSON.parse(rawUser)
    console.log('loaded user', user)

    return user
  } catch (_error) {
    // Invalid user
  }

  return state
}

handlers.SET_USER = (state, action) => {
  if (window) {
    window.localStorage.setItem('user', JSON.stringify(action.payload))
  }

  return action.payload
}

handlers.CLEAR_USER = (state, action) => null

handlers.FETCH_FAILED = (state, action) =>
  action.payload.status === 401
    ? null
    : state

export default buildReducer(null, handlers)
