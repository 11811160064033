const INITIAL_DATA = {
  invitations: {},
  items: {},
  users: {},
  outreachCoordinators: {},
  doctors: {},
  organs: {},
  pages: {}
}

const objectWithoutKey = (object, key) => {
  const {[key]: deletedKey, ...otherKeys} = object;
  return otherKeys;
}

function entities(state=INITIAL_DATA, action) {
  if (action.payload && action.payload.entities) {
    switch (action.type) {
      // If this email address has already been invited, make sure the previous one is removed.
      case 'INVITE_USER_SUCCEEDED':
        const invitations = state.invitations
        const invitationEntity = Object.values(action.payload.entities.invitations)[0];
        // const dupKey = invitations.findKey(i => i.get('email') === invitationEntity.email);
        const dupKey = Object.keys(invitations).find(k => invitations[k].email === invitationEntity.email)
        // state = state.set('invitations', invitations.remove(dupKey));
        state = {
          ...state,
          invitations: {
            ...invitations,
            [dupKey]: undefined
          }
        }
        // fallthrough
      default:
        return {
          ...state,
          ...action.payload.entities
        }
        // return state.mergeDeep(fromJS(action.payload.entities));
    }
  }

  switch (action.type) {
    case 'DELETE_INVITATION_SUCCEEDED':
      // const invitations = state.get('invitations');
      // return state.set('invitations', invitations.remove(action.payload.id));
      return {
        ...state,
        invitations: objectWithoutKey(state.invitations, action.payload.id)
      }

    case 'DELETE_USER_SUCCEEDED':
      // const users = state.get('users');
      // return state.set('users', users.remove(action.payload.id));
      return {
        ...state,
        users: objectWithoutKey(state.users, action.payload.id)
      }

    case 'SET_DOCTOR_ORGANS_SUCCEEDED':
      return {
        ...state,
        doctors: {
          ...state.doctors,
          [action.payload.id]: {
            ...state.doctors[action.payload.id],
            organs: action.payload.organs
          }
        }
      }

      // (({ b, c, ...o }) => o)(obj)
    case 'DELETE_DOCTOR_SUCCEEDED':
      // const doctors = state.get('doctors');
      // return state.set('doctors', doctors.remove(action.payload.id));
      return {
        ...state,
        doctors: objectWithoutKey(state.doctors, action.payload.id)
      }

    case 'DELETE_PAGE_SUCCEEDED':
      return {
        ...state,
        pages: objectWithoutKey(state.pags, action.payload.id)
      }
    //   {
    //   const pages = state.get('pages');
    //   return state.set('pages', pages.remove(action.payload.id));
    // }

    case 'UPDATE_PAGE_SUCCEEDED':
      return {
        ...state,
        pages: {
          ...state.pages,
          [action.payload.id]: {
            ...(state.pages[action.payload.id] || {}),
            name: action.payload.name,
            nav_name: action.payload.nav_name,
            content: action.payload.content,
            order: action.payload.order
          }
        }
      }
      // {
    //   const { id, name, nav_name, content, order } = action.payload;
    //
    //   const pages = state.get('pages');
    //   const page = pages.get(id)
    //     .set('name', name)
    //     .set('nav_name', nav_name)
    //     .set('content', content)
    //     .set('order', order);
    //
    //   return state.set('pages', pages.set(id, page));
    // }

    case 'UPDATE_PAGE_ORDER_SUCCEEDED':
      return {
        ...state,
        pages: {
          ...state.pages,
          [action.payload.id]: {
            ...(state.pages[action.payload.id] || {}),
            order: action.payload.order
          }
        }
      }
    //     {
    //   const { id, order } = action.payload;
    //
    //   const pages = state.get('pages');
    //   const page = pages.get(id).set('order', order)
    //
    //   return state.set('pages', pages.set(id, page));
    // }

    default:
      return state;
  }
}

export default entities;
