import { schema } from 'normalizr';

export const InvitationEntity = new schema.Entity('invitations');
export const OrganEntity = new schema.Entity('organs');
export const OutreachCoordinatorEntity = new schema.Entity('outreachCoordinators');
export const PageEntity = new schema.Entity('pages');
export const UserEntity = new schema.Entity('users');

export const DoctorEntity = new schema.Entity('doctors', {
  organs: [OrganEntity]
});
