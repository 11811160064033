import { post } from '../../api'

export const loadUser = () => dispatch =>
  dispatch({ type: 'LOAD_USER' })

export const login = ({ email, password }) => async dispatch =>
  post('/auth', {
    email,
    password
  })
    .then(body => dispatch({
      type: 'SET_USER',
      payload: body
    }))
    .catch(error => dispatch({
      type: 'LOGIN_FAILED',
      payload: error
    }))
