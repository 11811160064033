import React from 'react';

export default (props) => {
  const rows = props.users.map(u => {
    return (
      <tr key={u.id}>
          <td>
            {u.name}
          </td>
          <td>
            {u.email}
          </td>
          <td>
            {u.role}
          </td>
          <td>
            <button onClick={props.deleteUser.bind(null, u.id)}>delete</button>
          </td>
      </tr>
    );
  });

  return (
    <div className="table-responsive">
      <table className="table table-striped">
        <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Role</th>
          <th>Actions</th>
        </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    </div>
  );
};
