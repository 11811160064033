import Helmet from 'react-helmet';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { asyncConnect } from 'redux-connect';

import UsersTable from '../components/users-table';

import {
  deleteInvitation,
  deleteUser,
  fetchUserInvitations,
  fetchUsers,
  inviteUser
} from '../redux/actions/user-actions';

import '../scss/admins-page.scss';

const asyncItems = [
  {
    promise: ({ store: {dispatch} }) =>
      dispatch(fetchUsers())
  },
  {
    promise: ({ params, store: {dispatch} }) =>
      dispatch(fetchUserInvitations())
  }
];

const mapStateToProps = (state, props) => ({
  invitations: Object.values(state.entities.invitations),
  users: Object.values(state.entities.users)
});

const mapDispatchToProps = {
  deleteInvitation,
  deleteUser,
  inviteUser
}

class AdminsPage extends PureComponent {
  static contextTypes = {
    store: PropTypes.object.isRequired,
    authFetch: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = { email: '' };
  }

  updateEmail = (event) => {
    this.setState({ email: event.target.value });
  }

  inviteUser = (event) => {
    event.preventDefault();
    this.props.inviteUser(this.context.authFetch, this.state.email);
    this.setState({ email: '' });
  }

  deleteUser = (id) => {
    this.props.deleteUser(this.context.authFetch, id);
  }

  deleteInvitation = (id) => {
    this.props.deleteInvitation(this.context.authFetch, id);
  }

  render() {
    const { invitations, users } = this.props;

    const invitedUsers = invitations.length
      ? (
        <div>
          <h4>Invited Admins</h4>
          <UsersTable users={invitations} deleteUser={this.deleteInvitation} />
          <hr />
        </div>
      ) : null;

    return (
      <div className="admins-page">
        <Helmet title="Admins" />

        <h3>Manage Admins</h3>

        <div className="add-user-container">
          <form className="form-inline" onSubmit={this.inviteUser}>
            <div className="form-group">
              <label htmlFor="email">Add new user:</label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="john.doe@example.com"
                value={this.state.email}
                onChange={this.updateEmail}
              />
            </div>
            <button type="submit" className="btn btn-primary">Add</button>
          </form>
        </div>

        <hr />

        {invitedUsers}

        <h4>All Admins</h4>
        <UsersTable users={users} deleteUser={this.deleteUser} />
      </div>
    );
  }
}

export default asyncConnect(asyncItems, mapStateToProps, mapDispatchToProps)(AdminsPage)
