import Helmet from 'react-helmet';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { asyncConnect } from 'redux-connect';

import OutreachCoordinator from '../components/outreach-coordinator';

import {
  fetchAllOutreachCoordinators,
  updateOutreachCoordinator
} from '../redux/actions/outreach-actions';

import '../scss/outreach-coordinators-page.scss';

const asyncItems = [
  {
    promise: ({ params, store: {dispatch} }) =>
      dispatch(fetchAllOutreachCoordinators())
  }
];

const mapStateToProps = (state, props) => ({
  outreachCoordinators: Object.values(state.entities.outreachCoordinators)
});

const mapDispatchToProps = { updateOutreachCoordinator }

class OutreachCoordinatorsPage extends Component {
  constructor(props) {
    super(props);
    this.state = { values: {}, isSaving: false };
  }

  save = () => {
    this.setState({ isSaving: true });

    const { outreachCoordinators, updateOutreachCoordinator } = this.props;

    const promises = outreachCoordinators.map(oc => {
      const info = this.state.values[oc.id] || oc;
      return updateOutreachCoordinator(oc.id, info);
    });

    Promise.all(promises)
      .then(() => this.setState({ isSaving: false }));
  }

  valuesChanged = (oc) => {
    const { values } = this.state;
    values[oc.id] = oc;
    this.setState({ values });
  };

  render() {
    const { outreachCoordinators } = this.props;

    const oc = outreachCoordinators.map(oc => {
      const info = this.state.values[oc.id] || oc;
      return (
        <OutreachCoordinator
          key={oc.id}
          info={info}
          valuesChanged={this.valuesChanged}
        />
      );
    });

    const saveButton = this.state.isSaving
      ? <button className="btn btn-primary disabled"><i className="fa fa-spinner fa-spin"></i> Saving...</button>
      : <button className="btn btn-primary" onClick={this.save}>Save</button>;

    return (
      <div className="outreach-coordinators-page">
        <Helmet title="Outreach Coordinators" />

        <h3>Outreach Coordinators</h3>

        {oc}

        <div>
          {saveButton}
        </div>
      </div>
    );
  }
}

export default asyncConnect(asyncItems, mapStateToProps, mapDispatchToProps)(OutreachCoordinatorsPage)
