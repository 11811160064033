export default function session(state={}, action) {
  console.log('handling action', action.type)
  switch (action.type) {
    case 'SIGN_IN_SUCCEEDED':
    case 'SIGN_UP_SUCCEEDED':
      return {
        ...state,
        error: null
      }

    case 'SIGN_OUT':
      return {}

    case 'SIGN_IN_FAILED':
      return {
        ...state,
        error: action.error
      }

    default:
      return state;
  }
}

