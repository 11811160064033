import React from 'react';
import { Link } from 'react-router-dom';

import '../scss/header.scss';

export default (props) => {
  return (
    <nav className="navbar navbar-fixed-top">
      <div className="container-fluid">
        <div className="navbar-header">
          <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
            <span className="sr-only">Toggle navigation</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
          </button>
          <a className="navbar-brand" href="/">
            <img src="images/hftr-logo.svg" height="50" />
          </a>
        </div>

        <div id="navbar" className="navbar-collapse">
          <ul className="nav navbar-nav navbar-right">
            <li>
              <Link to="/signin" onClick={props.signOut}>
                <i className="fa fa-sign-out"></i> Sign out
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
