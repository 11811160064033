import { get, post } from '../../api'

export const getIsSignedIn = () => dispatch => 
  get('/auth/check')
    .then(() => true)
    .catch(() => false);

export const signIn = ({ email, password }) => dispatch =>
  post('/auth', {
    email,
    password
  })
    .then(body => dispatch({ type: 'SIGN_IN_SUCCEEDED' }))
    .catch(error => {
      dispatch({ type: 'SIGN_IN_FAILED', error })
      throw error;
    });

export function signUp(id, payload) {
  return dispatch =>
    post(`/auth/invitations/${id}`, payload)
      .then(body => dispatch({ type: 'SIGN_UP_SUCCEEDED' }))
      .catch(error => {
        dispatch({ type: 'SIGN_UP_FAILED', error })
        throw error;
      });
}

export function signOut() {
  return dispatch =>
    get('/auth/signout')
      .then(body => dispatch({ type: 'SIGN_OUT_SUCCEEDED' }))
      .catch(error => dispatch({ type: 'SIGN_OUT_FAILED', error }));
}

