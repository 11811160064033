import { del, get, post, put } from '../../api'
import { PageEntity } from './entities';
import { normalize } from 'normalizr';

export function createPage(name, nav_name, section, content, order) {
  return dispatch =>
    post('/pages', { name, section, content, order })
      .then(body => {
        const entities = normalize(body, { data: PageEntity }).entities;
        dispatch({
          type: 'CREATE_PAGE_SUCCEEDED',
          payload: { entities }
        });
        return body.data.id;
      })
      .catch(error => dispatch({ type: 'CREATE_PAGE_FAILED', error }));
}

export function deletePage(id) {
  return dispatch =>
    del(`/pages/${id}`)
      .then(body => {
        dispatch({
          type: 'DELETE_PAGE_SUCCEEDED',
          payload: { id }
        });
      })
      .catch(error => dispatch({ type: 'DELETE_PAGE_FAILED', error }));
}

export function fetchAllPages() {
  return dispatch =>
    get(`/pages`)
      .then(body => {
        const entities = normalize(body, { data: [PageEntity] }).entities;
        console.log('setting entities')
        return dispatch({
          type: 'FETCH_ALL_PAGES_SUCCEEDED',
          payload: { entities }
        });
      })
      .catch(error => dispatch({ type: 'FETCH_ALL_PAGES_FAILED', error }));
}

export function fetchPage(id) {
  return dispatch =>
    get(`/pages/${id}`)
      .then(body => {
        const entities = normalize(body, { data: PageEntity }).entities;
        dispatch({
          type: 'FETCH_PAGE_SUCCEEDED',
          payload: { entities }
        });
      })
      .catch(error => dispatch({ type: 'FETCH_PAGE_FAILED', error }));
}

export function updatePage(id, name, nav_name, content, order) {
  return dispatch =>
    put(`/pages/${id}`, { name, nav_name, content, order })
      .then(body => {
        dispatch({
          type: 'UPDATE_PAGE_SUCCEEDED',
          payload: { id, name, nav_name, content, order }
        });
      })
      .catch(error => dispatch({ type: 'UPDATE_PAGE_FAILED', error }));
}

export function reorderPages(orders) {
  return dispatch => {
    // NOTE: This assumes the request succeeds.
    orders.forEach(o => {
      dispatch({
        type: 'UPDATE_PAGE_ORDER_SUCCEEDED',
        payload: o
      });
    });

    return put(`/pages/reorder`, orders)
      .catch(error => dispatch({ type: 'UPDATE_PAGE_ORDERS_FAILED', error }));
  }
}
